/* eslint-disable @next/next/no-img-element */
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useContext } from 'react';

import { logTooltipAction } from '../../../graphQl/mutations';
import { MINIO_PUBLIC_PATHS, TooltipType } from '../../../shared/Constant';
import { handleErrorSnackbar, ThemeContext } from '../../../shared/Services/handler';
import LWPButton from '../Button/LWPButton';
import Paragraph from '../Typography/Paragraph';

const ToolTipWrapper = ({ tooltipTour, setTooltipTour, children }) => {
    const { userId, callSnackbar, token } = useContext(ThemeContext);

    const lastStep = +tooltipTour?.completed?.length + 1 == +tooltipTour?.upNext?.length;

    const [logTooltipActionMutation, { loading: logTooltipActionLoading }] = useMutation(logTooltipAction);

    const currentTooltip = toolTipItems?.[token?.userType]?.find(
        (item) => tooltipTour?.['upNext']?.includes(item.type) && !tooltipTour?.['completed']?.includes(item.type)
    );

    const upDateToolpit = () => {
        logTooltipActionMutation({
            variables: {
                type: currentTooltip?.type,
                userId: userId
            }
        })
            .then(() => {
                setTooltipTour((pre) => ({
                    ...pre,
                    completed: lastStep ? [] : [...pre.completed, pre?.current?.type],
                    upNext: lastStep ? [] : pre?.upNext,
                    finished: lastStep ? [...pre.completed, pre?.current?.type] : pre?.finished
                }));
            })
            .catch((error) => {
                handleErrorSnackbar(callSnackbar, error);
            });
    };

    const toolTipcontent = (context, upDateToolpit, loading, tooltipTour) => {
        return (
            <Grid width={350} padding={1} gap={1} display={'flex'} className="feedback_tooltip_text" alignItems={'flex-start'}>
                {context?.content?.image && <img src={context?.content?.image} alt="switch between" width={58} />}
                <Grid>
                    <Paragraph text={context?.content?.text} />
                    <br />
                    <Grid container justifyContent={'space-between'}>
                        {tooltipTour?.upNext?.length > 1 && (
                            <Paragraph text={`${+tooltipTour?.completed?.length + 1}/${tooltipTour?.upNext?.length}`} />
                        )}
                        <Grid>
                            <LWPButton
                                variant="text"
                                onClick={upDateToolpit}
                                btnText={lastStep ? 'Got it' : 'Next'}
                                color="inherit"
                                loading={loading}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    tooltipTour.current = {
        type: currentTooltip?.type,
        renderToolTip: toolTipcontent(currentTooltip, upDateToolpit, logTooltipActionLoading, tooltipTour)
    };

    setTooltipTour(tooltipTour);

    return children;
};

export default ToolTipWrapper;

const toolTipItems = {
    TEACHER: [
        {
            type: TooltipType.TOPIC_LIBRARY,
            content: {
                image: `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/Puzzle.svg`,
                text: 'Set topics for classes and groups from one place'
            }
        },
        {
            type: TooltipType.SCHOOL_READING_LOG,
            content: {
                image: `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/Book_Open_Purple.png`,
                text: 'Quickly record reading with one or several pupils'
            }
        },
        {
            type: TooltipType.TICK_ALL_FEEDBACK_AS_READ,
            content: {
                text: 'You can now mark all activities/reading log entries as read'
            }
        },
        {
            type: TooltipType.TICK_SINGLE_FEEDBACK_AS_READ,
            content: {
                text: 'You can mark single activity/reading log entry as read'
            }
        },
        {
            type: TooltipType.REPLY_ON_FEEDBACK,
            content: {
                text: 'You can now reply on activity/reading log entry.'
            }
        },
        {
            type: TooltipType.AWARD_STICKERS_TO_FEEDBACK,
            content: {
                text: 'You can now send sticker on activity/reading log entry.'
            }
        }
    ],
    PARENT: [
        {
            type: TooltipType.CHILD_SWITCH,
            content: {
                image: `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/switch_between.svg`,
                text: 'Use the menu to switch between your children!'
            }
        }
    ]
};
