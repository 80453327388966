import React, { CSSProperties } from 'react';

interface Heading3Props {
    text: string;
    customClass?: string;
    style?: CSSProperties;
    onClick?: () => void;
    children?: React.ReactNode;
}

const Heading3 = ({ text, customClass = '', children, style, onClick }: Heading3Props) => (
    <h3 style={style} className={`heading-three typography-text ${customClass}`} onClick={onClick}>
        {text}
        {children}
    </h3>
);

export default Heading3;
